<template>
  <GmapMap
    ref="mapRef"
    :center="{ lat: 52.092876, lng: 5.10448 }"
    :zoom="8"
    map-type-id="terrain"
    class="map"
    style="width: 100%"
  >
    <GmapMarker
      :key="index"
      v-for="(m, index) in savedLocations"
      :position="getPosition(m)"
      :icon="markerOptions"
    />
  </GmapMap>
</template>

<script>
import { gmapApi } from "vue2-google-maps";
import apiOffice from "../api/apiOffice";
const mapMarker = require("@/assets/marker/marker.png");
export default {
  name: "OfficeMap",

  data() {
    return {
      markerOptions: {
        url: mapMarker,
        //size: { width: 25.6, height: 25.6, f: "px", b: "px" },
        scaledSize: { width: 60.6, height: 80.6, f: "px", b: "px" },
      },
      image: `url(${require("@/assets/logo.png")})`,

      currentLocation: null,
      map: null,
      savedLocations: [],
    };
  },
  async mounted() {
    this.map = await this.$refs.mapRef.$mapPromise;
   try {
  const response = await apiOffice.getPlaceByID(this.$route.params.id);
  console.log(response);
  this.savedLocations = [response];
  this.centerMarker(response);
  this.adjustScreenSize();
  window.addEventListener("resize", this.adjustScreenSize);
} catch (error) {
  console.error(error);
}
  },
  destroyed() {
    window.removeEventListener("resize", this.adjustScreenSize);
  },
  methods: {
    
adjustScreenSize() {
  const mainContent = document.getElementById("mainContent");
  if (mainContent) {
    this.$refs.mapRef.$el.style.height = mainContent.style.height;
  }
},

    setAllMarkerCenter(zoomOffset = 4) {
      const bounds = new this.google.maps.LatLngBounds();
      this.savedLocations.forEach((marker) => {
        bounds.extend({
          lat: parseFloat(marker.lat_coord),
          lng: parseFloat(marker.long_coord),
        });
      });
      this.map.fitBounds(bounds);
      this.map.setZoom(this.map.getZoom() - zoomOffset);
    },
    centerMarker(marker, zoom = 12) {
      const bounds = new this.google.maps.LatLngBounds();
      bounds.extend({
        lat: parseFloat(marker.lat_coord),
        lng: parseFloat(marker.long_coord),
      });
      this.map.fitBounds(bounds);
      this.map.setZoom(zoom);
    },
    getPosition(location) {
      return (
        this.google &&
        new this.google.maps.LatLng(location.lat_coord, location.long_coord)
      );
    },
  },
  computed: {
    google: gmapApi,
    averageGeolocation() {
      if (this.markers.length === 0) {
        // Center of the netherlands
        return { lat: parseFloat(52.092876), lng: parseFloat(5.10448) };
      }
      if (this.markers.length === 1) {
        return {
          lat: parseFloat(this.markers[0].lat),
          lng: parseFloat(this.markers[0].lng),
        };
      }

      let x = 0.0;
      let y = 0.0;
      let z = 0.0;
      this.markers.forEach((marker) => {
        let latitude = (marker.lat * Math.PI) / 180;
        let longitude = (marker.lng * Math.PI) / 180;

        x += Math.cos(latitude) * Math.cos(longitude);
        y += Math.cos(latitude) * Math.sin(longitude);
        z += Math.sin(latitude);
      });

      let total = this.markers.length;

      x = x / total;
      y = y / total;
      z = z / total;

      let centralLongitude = Math.atan2(y, x);
      let centralSquareRoot = Math.sqrt(x * x + y * y);
      let centralLatitude = Math.atan2(z, centralSquareRoot);

      return {
        lat: parseFloat((centralLatitude * 180) / Math.PI),
        lng: parseFloat((centralLongitude * 180) / Math.PI),
      };
    },
    getImgUrl(marker) {
      var images = require.context("../../assets/", false, /\.png$/);
      return images("./" + marker + ".png");
    },
  },
};
</script>
<style scoped>
.vue-map-container {
  height: 625px;
}
</style>
