<template>
  <div>
    <!-- <img src="@/assets/banner.jpg" height="200" width="100%" /> -->

    <v-container fluid>
      <!-- <Gallery v-if="hasImages" :images="images" :buttonText="'Fotos bekijken'" /> -->

      <Gallery v-if="hasImages" :images="images">
        <template v-slot:viewPhotosButton>
          Fotos bekijken
        </template>
      </Gallery>

      <v-row>
        <v-col cols="12" md="7" lg="8">
          <div>
            <v-card elevation="8" style="padding:50px">
              <h5>
                <b>Bedrijfsnaam:</b>
                <span style="color:#64b5f6"> {{ building.officeName }}</span>
              </h5>

              <p><b>Adres: </b> {{ building.adres }}</p>

              <p><b>Prijs: </b>€ {{ building.price }}</p>
              <p>
                <b>Aantal personen: </b>
                {{ building.totalworkspace }} toegestaan
              </p>
              <p><b>Vierkantemeters :</b> {{ building.square }} m²</p>
              <v-divider> </v-divider>

              <h5><b>Type:</b></h5>
              {{ building.officeName }}
              <v-divider> </v-divider>

              <h5><b>Categorie</b></h5>

              <div
                v-for="categorie in building.categories"
                :key="categorie.categorie_id"
              >
                {{ categorie.categorie }}
              </div>

              <v-divider> </v-divider>

              <h5><b>Faciliteiten</b></h5>
              <div
                v-for="facility in building.facilities"
                :key="facility.facilitie_id"
              >
                {{ facility.facilitie }}
              </div>

              <v-divider> </v-divider>

              <h5><b>Extra informatie</b></h5>
              {{ building.description }}
              <div v-show="viewMore">
                Bedankt
              </div>
              <div>
                <div v-if="building.description > 0">
                  <v-btn color="error" @click="viewMore = !viewMore">
                    <span v-if="viewMore">Minder</span>
                    <span v-else>Meer</span>
                  </v-btn>
                </div>
              </div>
              <v-divider> </v-divider>
              <h5><b>Socialmedia</b></h5>

              <a :href="building.instagram" target="_blank">
                <v-icon large color="blue" class="icons">
                  mdi-instagram
                </v-icon>
              </a>
              <a :href="building.facebook" target="_blank">
                <v-icon large color="blue" class="icons">
                  mdi-facebook
                </v-icon>
              </a>
              <a :href="building.twitter" target="_blank">
                <v-icon large color="blue" class="icons">
                  mdi-twitter
                </v-icon>
              </a>
              <a :href="building.youtube" target="_blank">
                <v-icon large color="blue" class="icons">
                  mdi-youtube
                </v-icon>
              </a>
              <v-divider> </v-divider>
              <p>
                <b> Aangeboden sinds: </b>
                {{ new Date(building.createDate).toLocaleDateString() }}
              </p>
              <v-divider> </v-divider>
              <h5><b>Locatie</b></h5>
              <OfficeMap />
            </v-card>
          </div>
        </v-col>
        <!-- //Booking -->
        <v-col cols="12" md="5" lg="4">
          <v-card>
            <v-container>
              <v-card-title>
                <v-toolbar
                  color="#64b5f6"
                  dense
                  elevation="4"
                  flat
                  outlined
                  rounded
                  shaped
                >
                  <span style="color:white"> Reserveren </span>
                </v-toolbar>
              </v-card-title>

              <v-menu
                ref="menu1"
                v-model="menu1"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="dateFormatted"
                    @blur="startdate = parseDate(dateFormatted)"
                    label="Startdatum"
                    hint="DD/MM/YYYY 
                      
                      "
                    persistent-hint
                    prepend-icon="mdi-calendar"
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="startdate"
                  no-title
                  @input="menu1 = false"
                ></v-date-picker>
              </v-menu>
              <hr />
              <div style="margin-top: 20px; margin-bottom: 5px">
                <v-select
                  v-model="select"
                  :items="items"
                  label="Selecteer optie"
                  solo
                  :disabled="!isLoggedIn"
                ></v-select>
                <div v-if="select == 'Per dagdeel'">
                  <v-radio-group v-model="reservation.dagdeel" row>
                    <v-radio
                      color="red"
                      label="Ochtend"
                      value="Ochtend"
                    ></v-radio>
                    <v-radio
                      color="red"
                      label="Middag"
                      value="Middag"
                    ></v-radio>
                    <v-radio
                      color="red"
                      label="Hele dag"
                      value="Hele dag"
                    ></v-radio>
                  </v-radio-group>
                </div>

                <div v-if="select == 'Per uur'">
                  <v-row>
                    <v-col>
                      <v-menu
                        ref="menu5"
                        v-model="menu2"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        :return-value.sync="time2"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="time2"
                            label="Begin tijd"
                            prepend-icon="mdi-clock-time-four-outline"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-time-picker
                          v-if="menu2"
                          v-model="time2"
                          full-width
                          :allowed-minutes="allowedStep"
                          format="24hr"
                          @click:minute="$refs.menu5.save(time2)"
                        ></v-time-picker>
                      </v-menu>
                    </v-col>

                    <v-col>
                      <!-- Eind tijd -->

                      <v-menu
                        ref="menu4"
                        v-model="menu3"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        :return-value.sync="time3"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="time3"
                            label="Eind tijd"
                            prepend-icon="mdi-clock-time-four-outline"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-time-picker
                          v-if="menu3"
                          v-model="time3"
                          full-width
                          :allowed-minutes="allowedStep"
                          format="24hr"
                          @click:minute="$refs.menu4.save(time3)"
                        ></v-time-picker>
                      </v-menu>
                    </v-col>
                  </v-row>
                </div>
              </div>
              <hr />

              <div style="margin-top: 5px">
                <v-text-field
                  solo
                  label="Aantal personen"
                  v-model="reservation.aantalPersonen"
                  prepend-inner-icon="mdi-account-group"
                  required
                  :disabled="!isLoggedIn"
                  :error-messages="aantalPersonenError"
                ></v-text-field>
              </div>
              <v-textarea
                v-model="reservation.info"
                outlined
                name="input-7-4"
                label="Extra informatie"
                placeholder="Opmerking of vragen"
                :disabled="!isLoggedIn"
              ></v-textarea>

              <span v-if="select == 'Per dagdeel' || select == 'Per dagdeel'">
                <p>Totaal (* Het bedrag is inclusief btw*)</p>
              </span>
              <v-text-field
                v-if="select == 'Per dagdeel'"
                v-model="bedrag"
                label="Bedrag"
                solo
                disabled
                >Euro</v-text-field
              >

              <v-text-field
                v-if="select == 'Per uur'"
                v-model="bedragPerUur"
                label="BedragPerUur"
                solo
                disabled
                >Euro</v-text-field
              >

              <v-card-actions v-if="!isLoggedIn" style="margin-top: 5px">
                <v-btn dark block @click="login()"> Inloggen</v-btn>
              </v-card-actions>
              <span v-if="!isLoggedIn">
                <p class="text-center" style="color:red">
                  ** Login om te reserveren **
                </p>
              </span>

              <v-card-actions v-if="isLoggedIn" style="margin-top: 5px">
                <v-btn dark block @click="bookAppointment()">
                  Reserveren
                </v-btn>
              </v-card-actions>
              <p>
                <v-icon> mdi-help-circle-outline </v-icon>Vooraf betaling bij
                het garanderen van een plek
              </p>
            </v-container>
          </v-card>
        </v-col>
      </v-row>

      <!-- Pop Up -->
    </v-container>
  </div>
</template>

<script>
import apiOffice from "../api/apiOffice";
import OfficeMap from "./OfficeMap";
import apiAppointment from "../api/apiAppointment";
import firebase from "firebase";
import Gallery from "vue-cover-gallery";
// import apiPayment from "../apiPayment";
export default {
  name: "Map",
  data: (vm) => {
    return {
      time2: null,
      menu2: false,
      time3: null,
      menu3: false,
      menu1: false,
      isLoggedIn: false,
      startdate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      dateFormatted: vm.formatDate(
        new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10)
      ),
      viewMore: false,
      name: "",
      select: "",
      currentUrl: "",
      statusInfo: [],
      building: [],
      items: ["Per dagdeel", "Per uur"],
      reservation: {
        dagdeel: "",
        aantalPersonen: "",
        info: "",
      },
    };
  },

  components: {
    Gallery,
    OfficeMap,
  },

  async created() {
    this.currentUrl = window.location.href;
    await this.getUser();
  },

  async mounted() {
    const id = this.$route.params.id;
    apiOffice.getPlaceByID(id).then((data) => {
      this.building = data;
    });
    await this.getImageUrl();
  },

  watch: {
    startdate() {
      this.dateFormatted = this.formatDate(this.startdate);
    },
  },

  computed: {
    aantalPersonenError() {
      return this.validateAantalPersonen();
    },
    hasImages() {
      if (
        this.building &&
        this.building.images &&
        this.building.images.length > 0
      ) {
        return true;
      }
      return false;
    },

    computedDateFormatted() {
      return this.formatDate(this.startdate);
    },
    images() {
      return this.building.images.map((image) => ({
        href: `https://server.kantoorflex.nl/static/${image.filename}`,
      }));
    },
    bedrag() {
      if (
        this.reservation.dagdeel == "Ochtend" ||
        this.reservation.dagdeel == "Middag"
      ) {
        const price = this.building.price * 4;
        const totalPrice = Number(price.toFixed(2));
        return totalPrice.toFixed(2);
      } else if (this.reservation.dagdeel == "Hele dag") {
        const price = this.building.price * 8;
        const totalPrice = Number(price.toFixed(2));
        return totalPrice.toFixed(2);
      } else {
        return 0; // or return null;
      }
    },
    bedragPerUur() {
      if (!this.time2 || !this.time3) {
        return "Uren moeten nog ingevuld worden";
      }
      const start = this.time2.split(":");
      const end = this.time3.split(":");
      const startTime = new Date(0, 0, 0, start[0], start[1], 0);
      const endtime = new Date(0, 0, 0, end[0], end[1], 0);
      const timeDifference = endtime.getTime() - startTime.getTime();
      const ONE_HOUR = 1000 * 60 * 60;
      const FIFTEEN_MINUTES = 1000 * 60 * 15;
      const differenceInHours = Math.floor(timeDifference / ONE_HOUR);
      const leftoverQuarters =
        Math.ceil((timeDifference % ONE_HOUR) / FIFTEEN_MINUTES) * 0.25;
      console.log(
        `HOURS: ${differenceInHours} : QUARTERS: ${leftoverQuarters}`
      );
      return (
        "Euro " + (differenceInHours + leftoverQuarters) * this.building.price
      );
    },
    isValidAppointment() {
      let aantalPersonen = !!this.reservation.aantalPersonen;
      let dagdeel = !!this.reservation.dagdeel;
      return aantalPersonen && dagdeel;
    },
  },
  methods: {
    validateAantalPersonen() {
      if (this.reservation.aantalPersonen < this.building.totalworkspace) {
        return (
          "Aantal personen moet gelijk of groter zijn dan " +
          this.building.totalworkspace
        );
      } else {
        return "";
      }
    },
    login() {
      this.$router.push("/Inloggen");
    },
    resetForm() {
      this.reservation.dagdeel = "";
      this.reservation.info = "";
      this.aantalPersonen = "";
    },

    async bookAppointment() {
      if (this.isValidAppointment) {
        try {
          const data = {
            per_dagdeel: this.reservation.dagdeel,
            info: this.reservation.info,
            aantalPersonen: this.reservation.aantalPersonen,
            appointment_date: this.reformatDate(this.dateFormatted),
            office_id: this.$route.params.id,
            from_time: this.time2,
            to_time: this.time3,
          };
          const response = await apiAppointment.makeAppointment(data);
          window.location.href = response.url;
  
        } catch (error) {
          console.error(error);
          this.$swal.fire("Er is een foutmelding!", error.message, "error");
        }
      }
    },
    getUser() {
      firebase.auth().onAuthStateChanged(async (user) => {
        console.log({ user: firebase.auth().currentUser });
        this.isLoggedIn = !!user;
        this.user = user ? user : null;
      });
    },
    allowedStep(minute) {
      return minute % 15 === 0;
    },

    getImageUrl(path) {
      return `https://server.kantoorflex.nl/static/${path}`;
    },
    reformatDate(date){
      const [day,month,year] = date.split("/")
      return new Date(year,month-1,day)
    },
    formatDate(startdate) {
      if (!startdate) return null;

      const [year, month, day] = startdate.split("-");
      return `${day}/${month}/${year}`;
    },
    parseDate(startdate) {
      if (!startdate) return null;

      const [year, month, day] = startdate.split("/");
      return `${day.padStart(2, "0")}-${month.padStart(2, "0")}-${year}`;
    },
  },
};
</script>

<style>
.icons {
  margin-left: 10px;
}
.img-left {
  height: 500px !important;
}
.img-right {
  height: 250px !important;
}
.image {
  margin-top: 20px;
}
</style>
